import React, { Component } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { PageLayout } from '../components'
import { SearchBar } from '../components/common'

export default class NotFoundPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isPowerToProtect: false,
            sitemap: [],
        }
    }

    componentDidMount() {
        const isPowerToProtect = window?.location.pathname.includes('power-to-protect')
        this.setState({ isPowerToProtect })

        // Conditionally set the sitemap based on the URL
        const sitemap = isPowerToProtect ? get(this.props, 'data.allContentfulWidgetSitemapPtp.edges', []).map((link) => link.node) : get(this.props, 'data.allContentfulWidgetSitemap.edges', []).map((link) => link.node)
        this.setState({ sitemap })
    }

    render() {
        const { sitemap } = this.state

        return (
            <PageLayout sitemap={sitemap} className={` generic-page ${this.state.isPowerToProtect ? 'ptp-root' : ''}`}>
                <main className={`wrapper full-page page-404`}>
                    <h1>Page not found</h1>
                    <p>
                        The page you requested could not be found. Please try to search again or <a href="/contact-us">contact us</a>.
                    </p>
                    <div className="search-panel">
                        <div className="searchbox-wrapper">
                            <SearchBar id={'searchbar-panel-widget'} withOverlay={false} alwaysActive={true} isPowerToProtect={this.state.isPowerToProtect} />
                        </div>
                    </div>
                </main>
            </PageLayout>
        )
    }
}

export const pageQuery = graphql`
    query getSiteMaps {
        allContentfulWidgetSitemap {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageCustom {
                            slug
                            title
                            contentful_id
                            userSection
                        }
                        ... on ContentfulPageDirectoryOfServices {
                            id
                            title
                        }
                        ... on ContentfulPageDonation {
                            slug
                            title
                            contentful_id
                        }
                        ... on ContentfulPageGeneric {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
        allContentfulWidgetSitemapPtp(filter: { title: { ne: "Don't delete - sitemap" } }) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageDirectoryOfServicesPtp {
                            id
                            title
                        }
                        ... on ContentfulPageGenericPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroupPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageWidgetsPtp {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
